.image-gallery-thumbnails {
  /* overflow: hidden; */
  /* overflow-y: scroll !important; */
  overflow-y: auto !important;
  overflow: hidden;
}
:where(.css-dev-only-do-not-override-17sses9).ant-select-dropdown
  .ant-select-item-option-content {
  white-space: normal;
}

:where(.css-17sses9).ant-select-dropdown .ant-select-item-option-content {
  white-space: normal;
}

.image-gallery-thumbnails::-webkit-scrollbar {
  display: none;
}

:where(.css-dev-only-do-not-override-17sses9).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #7a7c7d;
}

:where(.css-17sses9).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #7a7c7d;
}

:where(.css-dev-only-do-not-override-17sses9).ant-select-single.ant-select-lg
  .ant-select-selector {
  border-radius: 4px;
}

:where(.css-17sses9).ant-select-single.ant-select-lg .ant-select-selector {
  border-radius: 4px;
}

.image-gallery {
  border: 1px solid black;
  margin-bottom: 10px;
}

.container-1 {
  width: 100%;
  max-width: 100%;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  height: 100vh;
}

.tool-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #f0f3f5;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 9;
  height: 100vh;
}

.tool-image,
.tool-image-n {
  width: 35px;
  height: 35px;
  margin: 3px;
  padding: 3px;
  cursor: pointer;
}

.tool-image:hover {
  /* transform: scale(1.5); */
  transform: translateX(5px) scale(1.2);
  transition-delay: 20ms;
}

.zero-pointer {
  cursor: url(./assets/img/eval_images/0.png), auto;
}

.half-pointer {
  cursor: url(./assets/img/eval_images/1-2.png), auto;
}

.one-pointer {
  cursor: url(./assets/img/eval_images/1.png), auto;
}

.two-pointer {
  cursor: url(./assets/img/eval_images/2.png), auto;
}

.three-pointer {
  cursor: url(./assets/img/eval_images/3.png), auto;
}
.four-pointer {
  cursor: url(./assets/img/eval_images/4.png), auto;
}
.five-pointer {
  cursor: url(./assets/img/eval_images/5.png), auto;
}
.six-pointer {
  cursor: url(./assets/img/eval_images/6.png), auto;
}
.seven-pointer {
  cursor: url(./assets/img/eval_images/7.png), auto;
}

.eight-pointer {
  cursor: url(./assets/img/eval_images/8.png), auto;
}

.nine-pointer {
  cursor: url(./assets/img/eval_images/9.png), auto;
}

.ten-pointer {
  cursor: url(./assets/img/eval_images/10.png), auto;
}

.eleven-pointer {
  cursor: url(./assets/img/eval_images/11.png), auto;
}

.twelve-pointer {
  cursor: url(./assets/img/eval_images/12.png), auto;
}

.thirteen-pointer {
  cursor: url(./assets/img/eval_images/13.png), auto;
}
.fourteen-pointer {
  cursor: url(./assets/img/eval_images/14.png), auto;
}

.fifteen-pointer {
  cursor: url(./assets/img/eval_images/15.png), auto;
}

.sixteen-pointer {
  cursor: url(./assets/img/eval_images/16.png), auto;
}
.seventeen-pointer {
  cursor: url(./assets/img/eval_images/17.png), auto;
}

.eighteen-pointer {
  cursor: url(./assets/img/eval_images/18.png), auto;
}

.nineteen-pointer {
  cursor: url(./assets/img/eval_images/19.png), auto;
}

.twenty-pointer {
  cursor: url(./assets/img/eval_images/20.png), auto;
}

.marks-half-pointer {
  z-index: -9;
  background-image: url(./assets/img/eval_images/7.png);
}

.login-bg {
  background-image: url(./assets/bg-image.jpeg);
  background-position: center;
}

.blurry-bg {
  background-image: url(./assets/blurry-bg.jpeg);
  background-position: center;
  height: 100%;
}

.login-logo {
  width: 17em;
  margin-top: 8em;
}

.login-divider {
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(to left, #52c234, #061700);
  border-image-slice: 1;
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
  border: 1px solid rgba(34, 36, 38, 0.6);
}

.ui.selection.dropdown {
  border: 1px solid rgba(34, 36, 38, 0.6);
}

.ui.table td.bg-green,
.ui.table tr.bg-green {
  background: #2c662d !important;
  color: #fff !important;
}

.ui.checkbox.bg-green label,
.ui.checkbox.bg-green + label {
  color: #fff !important;
}

.no-margin {
  margin: 0px !important;
}

.app {
  height: 100vh;
}

html body .app.flex-row.align-items-center {
  height: 100vh;
}

.app,
app-dashboard,
app-root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.align-items-center {
  -webkit-align-items: center !important;
  align-items: center !important;
}

.flex-row {
  -webkit-flex-direction: row !important;
  flex-direction: row !important;
}

.card-center {
  margin: 0px auto !important;
}

.ui.input.success > input {
  background-color: #f7fff6;
  border-color: #b8e0b4;
  color: #389f4f;
  box-shadow: none;
}

.success {
  background-color: #f7fff6 !important;
  border-color: #b8e0b4 !important;
  color: #389f4f !important;
}
.error {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
}
.ui.input.center > input {
  text-align: center;
  line-height: 0.61428571em;
  padding: 0.47857143em 1em;
}

.val-radio {
  margin: 0px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 80px !important;
  width: 40px !important;
}

.ui.compact.table td {
  padding: 0.4em 0.5em;
}

.ui.progress:first-child {
  margin: 0 0 0.5em;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 140vh !important;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  height: 140vh !important;
}

.image-gallery-thumbnail-image {
  outline: none;
  border: 1px solid #363c41;
}

.sub-btn > button {
  margin: 0.3em !important;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 0px !important;
}

.ui.input.valcntr > input {
  border: 0px solid rgba(34, 36, 38, 0.15) !important;
}

.headfootfixed_table {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
}

.headfootfixed_header {
  /* background-color: green; */
}

.headfootfixed_body {
  /* background-color: yellow; */
  max-height: 75vh;
  overflow-y: scroll;
}

.headfootfixed_footer {
  /* background-color: purple; */
}
